<template>
  <FormulateForm
    #default="{ isLoading }"
    v-model="values"
    name="wizardDossierDetailsStep5Form"
    invalid-message="Gelieve de verplichte velden correct in te vullen."
    @submit="submit"
  >
    <h3>Kadaster</h3>
    <FormulateInput
      name="cadastral_department"
      label="Kadastrale afdeling"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <FormulateInput
      name="cadastral_section"
      label="Kadastrale sectie"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <FormulateInput
      name="lot_number"
      label="Perceelnummer"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <FormulateInput
      name="partition_number"
      label="Partitienummer"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <FormulateInput
      type="number"
      name="cadastral_income"
      label="Kadastraal inkomen (niet-geïndexeerd)"
      min="0"
      step=".01"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :input-class="['tw-rounded-tr-none tw-rounded-br-none !tw-mt-1']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
    >
        <div slot="suffix" class="input-unit">
          €
        </div>
    </FormulateInput>
    <hr class="tw-my-4" />
    <h3>Gewestinfo</h3>
    <FormulateInput
      type="select"
      name="destination_type"
      label="Type bestemming"
      :options="DESTINATION_TYPES"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :input-class="['!tw-mt-0']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <FormulateInput
      type="select"
      name="destination_type_secundairy"
      label="Type secundaire bestemming"
      :options="DESTINATION_TYPES"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :input-class="['!tw-mt-0']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <FormulateInput
      type="translatable"
      name="destination_info"
      label="Stedenbouwkundige bestemming - extra info"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3 tw-w-full']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :outer-class="['tw-flex tw-items-center']"
      :element-class="['tw-col-span-2']"
    />
    <hr class="tw-my-4" />
    <h3>Beschermd onroerend erfgoed</h3>
    <CustomRadioGroup
      v-model="values.heritage_protected_monument"
      :options="YES_NO_NOT_ENTERED_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="heritage_protected_monument"
      label="Beschermd monument"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.heritage_protected_area"
      :options="YES_NO_NOT_ENTERED_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="heritage_protected_area"
      label="Beschermd landschap"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.heritage_protected_cultural_area"
      :options="YES_NO_NOT_ENTERED_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="heritage_protected_cultural_area"
      label="Beschermd cultuurhistorisch landschap"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.heritage_protected_city"
      :options="YES_NO_NOT_ENTERED_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="heritage_protected_city"
      label="Beschermd stadsgezicht"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.heritage_protected_village"
      :options="YES_NO_NOT_ENTERED_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="heritage_protected_village"
      label="Beschermd dorpsgezicht"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.heritage_protected_archaeological_site"
      :options="YES_NO_NOT_ENTERED_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="heritage_protected_archaeological_site"
      label="Beschermde archeologische site"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.heritage_protected_archaeological_zone"
      :options="YES_NO_NOT_ENTERED_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="heritage_protected_archaeological_zone"
      label="Beschermde archeologische zone"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.heritage_protected_archaeological_monument"
      :options="YES_NO_NOT_ENTERED_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="heritage_protected_archaeological_monument"
      label="Beschermde archeologisch monument"
      class="tw-mb-4"
    />
    <FormulateInput
      type="translatable"
      name="heritage_inventory_info"
      label="Onroerend erfgoed - extra info"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3 tw-w-full']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :outer-class="['tw-flex tw-items-center']"
      :element-class="['tw-col-span-2']"
    />
    <hr class="tw-my-4" />
    <h3>Geïnventariseerd onroerend erfgoed</h3>
    <CustomRadioGroup
      v-model="values.heritage_landscape_atlas"
      :options="YES_NO_NOT_ENTERED_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="heritage_landscape_atlas"
      label="Landschapsatlas"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.heritage_inventory_archaeological_zones"
      :options="YES_NO_NOT_ENTERED_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="heritage_inventory_archaeological_zones"
      label="Inventaris van archeologische zones"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.heritage_inventory_architectural"
      :options="YES_NO_NOT_ENTERED_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="heritage_inventory_architectural"
      label="Inventaris van bouwkundig erfgoed"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.heritage_inventory_planting"
      :options="YES_NO_NOT_ENTERED_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="heritage_inventory_planting"
      label="Inventaris van houtige beplantingen met erfgoedwaarde"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.heritage_inventory_historical_gardens_parks"
      :options="YES_NO_NOT_ENTERED_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="heritage_inventory_historical_gardens_parks"
      label="Inventaris van historische tuinen en parken"
      class="tw-mb-4"
    />

    <div class="tw-grid tw-grid-cols-2 md:tw-flex tw-justify-end tw-gap-x-2">
      <FormulateErrors />
      <FormulateInput
        type="button"
        :input-class="['tw-w-full tw-bg-gray-500']"
        :disabled="saving"
        @click="goBack"
      >
        <i class="fas tw-mr-2 fa-arrow-left" />
        Vorige <span class="tw-hidden md:tw-inline">stap</span>
      </FormulateInput>
      <FormulateInput
        type="submit"
        title="Volgende stap"
        :input-class="['tw-w-full']"
        :disabled="isLoading"
      >
        <i
          :class="[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-arrow-right'
          ]"
        />
        Volgende <span class="tw-hidden md:tw-inline">stap</span>
      </FormulateInput>
    </div>
  </FormulateForm>
</template>

<script>
import { DESTINATION_TYPES } from '@/forms/schemas/portfolio/legal'
import { YES_NO_NOT_ENTERED_DOSSIER_DETAILS_WIZARD_OPTIONS } from '@/forms/selectOptions'
import CustomRadioGroup from '@/components/base/CustomRadioGroup'
import { updatePropertyFinancial, updatePropertyLegal } from '@/services/properties'

export default {
  name: 'WizardDossierDetailsStep5',
  components: {
    CustomRadioGroup
  },
  constants: {
    DESTINATION_TYPES,
    YES_NO_NOT_ENTERED_DOSSIER_DETAILS_WIZARD_OPTIONS
  },
  props: {
    initialValues: {
      type: Object
    },
    propertyId: {
      type: [Number, String]
    }
  },
  data () {
    return {
      values: {},
      saving: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.values = { ...this.initialValues }
    },
    async goBack () {
      this.saving = true
      const response = await this.submit(this.values, false)
      this.saving = false
      return response
    },
    async submit (data, goForward = true) {
      try {
        const {
          cadastral_department,
          cadastral_income,
          cadastral_section,
          lot_number,
          partition_number,
          destination_info,
          destination_type,
          destination_type_secundairy,
          heritage_inventory_archaeological_zones,
          heritage_inventory_architectural,
          heritage_inventory_historical_gardens_parks,
          heritage_inventory_info,
          heritage_inventory_planting,
          heritage_landscape_atlas,
          heritage_protected_archaeological_monument,
          heritage_protected_archaeological_site,
          heritage_protected_archaeological_zone,
          heritage_protected_area,
          heritage_protected_city,
          heritage_protected_cultural_area,
          heritage_protected_monument,
          heritage_protected_village,
          price, // Not used, gives errors when including in the financial PATCH payload
          ...financialPayload
        } = data
        const legalPayload = {
          partition_number,
          destination_info,
          destination_type,
          destination_type_secundairy,
          heritage_inventory_archaeological_zones,
          heritage_inventory_architectural,
          heritage_inventory_historical_gardens_parks,
          heritage_inventory_info,
          heritage_inventory_planting,
          heritage_landscape_atlas,
          heritage_protected_archaeological_monument,
          heritage_protected_archaeological_site,
          heritage_protected_archaeological_zone,
          heritage_protected_area,
          heritage_protected_city,
          heritage_protected_cultural_area,
          heritage_protected_monument,
          heritage_protected_village
        }
        const financialPromise = updatePropertyFinancial(this.propertyId, financialPayload)
        const legalPromise = updatePropertyLegal(this.propertyId, legalPayload)
        const promises = [financialPromise, legalPromise]
        const response = await Promise.all(promises)
        if (goForward) this.$emit('finished')
        else this.$emit('goBack')
        return response
      } catch (error) {
        this.$formulate.handle(error, 'wizardDossierDetailsStep5Form')
        throw error
      }
    }
  }
}
</script>
