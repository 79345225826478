<template>
  <div>
    <div class="tw-mb-8">
      <h2>Commissie</h2>
      <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
        <FormulateInput
          v-if="!isSales"
          v-model="formValues.commission_type_in_rental"
          type="select"
          name="commission_type_in_rental"
          label="Commissietype"
          placeholder="Commissietype"
          :options="[
            { value: ID_COMMISSION_TYPE_FREE, label: 'Gratis verhuring ihkv herverhuur of beheer' },
            { value: ID_COMMISSION_TYPE_ONE_MONTH_RENT, label: 'Één maand huur' },
            { value: ID_COMMISSION_TYPE_5_PERCENT_ANNUAL_RENT, label: '5% op jaarhuur' },
            { value: ID_COMMISSION_TYPE_10_PERCENT_ANNUAL_RENT, label: '10% op jaarhuur' },
            { value: ID_COMMISSION_TYPE_15_PERCENT_ANNUAL_RENT, label: '15% op jaarhuur' },
            { value: ID_COMMISSION_TYPE_FIXED_VALUE_RENT, label: 'Andere' }
          ]"
          validation="bail|required|number"
          :disabled="formValues.has_transaction"
          outer-class="tw-m-0"
        />
        <FormulateInput
          v-if="isSales"
          v-model="formValues.commission_type_in_sales"
          type="radio"
          name="commission_type_in_sales"
          :options="{
            percentage: 'Percentage',
            fix: 'Fix'
          }"
          :input-class="['tw-my-4']"
          outer-class="tw-m-0"
          @input="handleCommissionTypeChange"
        />
        <div v-if="isSales" class="tw-grid md:tw-grid-cols-1">
          <FormulateInput
            v-if="isSales"
            v-model="formValues.commission_percentage_in"
            id="commission_percentage_in"
            type="number"
            name="commission_percentage_in"
            placeholder="Percentage"
            validation="bail|required|number|min:0"
            step=".01"
            min="0"
            lang="nl"
            :disabled="!disableCommissionField"
            outer-class="tw-m-0"
            :element-class="['tw-flex tw-items-center']"
            :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
          >
            <div slot="suffix" class="input-unit">
              <i class="fas fa-percent" />
            </div>
          </FormulateInput>
          <FormulateInput
            id="commission_fix_in"
            v-model="formValues.commission_fix_in"
            type="number"
            name="commission_fix_in"
            placeholder="Fix"
            validation="bail|required|number|min:0"
            step=".01"
            min="0"
            lang="nl"
            :disabled="disableCommissionField"
            outer-class="tw-m-0"
            :element-class="['tw-flex tw-items-center']"
            :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
          >
            <div slot="suffix" class="input-unit">
              <i class="fas fa-euro-sign" />
            </div>
          </FormulateInput>
        </div>
        <div v-if="formValues.commission_type_in_rental === ID_COMMISSION_TYPE_FIXED_VALUE_RENT">
          <FormulateInput
            v-model="formValues.commission_rental_fix_value"
            id="commission_rental_fix_value"
            type="number"
            name="commission_rental_fix_value"
            label="Commissiebedrag"
            placeholder="Commissiebedrag"
            validation="bail|required|number|min:0"
            step=".01"
            min="0"
            lang="nl"
            :disabled="disableCommissionField"
            outer-class="tw-m-0"
            :element-class="['tw-flex tw-items-center']"
            :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
          >
            <div slot="suffix" class="input-unit">
              <i class="fas fa-euro-sign" />
            </div>
          </FormulateInput>
        </div>
      </div>
      <div v-if="formattedCommission" class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
        <p><b>Vooropgesteld commissiebedrag: {{ formattedCommission }}</b></p>
      </div>
    </div>
    <div>
      <h2>Mandaat</h2>
      <div class="tw-my-4 tw-grid md:tw-grid-cols-1 tw-gap-x-4">
        <FormulateInput
          type="radio"
          v-model="formValues.mandate_type"
          name="mandate_type"
          :options="{
            exclusive: 'Exclusieve opdracht',
            co_mandate: 'Co-mandaat',
            open_mandate: 'Open mandaat'
          }"
          validation="required"
          validation-name="Type mandaat"
          :input-class="['tw-my-1']"
          outer-class="tw-m-0 tw-mb-4"
          :disabled="prefillCoMandate"
          @input="handleMandateTypeChange"
        />
         <FormulateInput
          v-if="formValues.mandate_type === 'open_mandate'"
          v-model="formValues.send_monthly_owner_reports"
          type="checkbox"
          name="send_monthly_owner_reports"
          label="Automatische maandelijkse rapporten versturen"
          outer-class="tw-my-0"
        />
        <div v-if="formValues.mandate_type === 'co_mandate'">
          <CoMandateInputs
            ref="coMandateInputs"
            :price="price"
            :transaction-type="property.transaction_type"
            :commission-type="formValues.commission_type_in_sales"
            :commission-percentage="parseFloat(formValues.commission_percentage_in)"
            :calculated-commission="parseFloat(calculatedCommission)"
          />
        </div>
      </div>
      <div class="tw-my-4 tw-grid md:tw-grid-cols-3 tw-gap-4">
        <FormulateInput
          type="date"
          v-model="formValues.mandate_signature_date"
          name="mandate_signature_date"
          label="Datum ondertekening opdracht"
          placeholder="YYYY-MM-DD"
          validation="bail|required|date:YYYY-MM-DD"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="date"
          v-model="formValues.mandate_start_date"
          name="mandate_start_date"
          label="Startdatum opdracht"
          placeholder="YYYY-MM-DD"
          validation="bail|required|date:YYYY-MM-DD"
          :disabled="formValues.has_transaction"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="date"
          v-model="formValues.mandate_end_date"
          name="mandate_end_date"
          label="Einddatum opdracht"
          placeholder="YYYY-MM-DD"
          validation="bail|optional|date:YYYY-MM-DD"
          outer-class="tw-m-0"
        />
        <FormulateInput
          type="file"
          v-model="formValues.mandate_file"
          name="mandate_file"
          label="Opdrachtdocument"
          :uploader="uploadFile"
          outer-class="formulate-input tw-my-0"
          wrapper-class="formulate-input-wrapper"
          :upload-area-mask-class="['tw-h-10 tw-mt-1']"
          :file-class="['tw-h-10 !tw-mt-1']"
          element-class="formulate-input-element formulate-input-element--file"
        >
          <template #file="context">
            <BaseFileUploadDisplay v-bind="context" />
          </template>
        </FormulateInput>
      </div>
    </div>
  </div>
</template>

<script>
import { currency, today } from '@/utils/helpers'
import { createPropertyFile, createPropertyFileFolder, getPropertyFileFolders, updateProperty, uploadFile } from '@/services/properties'
import { createTransaction } from '@/services/transactions'
import { errorModal } from '@/modalMessages'
import CoMandateInputs from '@/components/transactions/CoMandateInputs'

const FILE_TYPES = {
  2: 53, // If transaction_type is 'Te koop'
  3: 52, // If transaction_type is 'Te huur'
  4: 76 // If transaction_type is 'Over te nemen'
}

export default {
  name: 'TransactionIntakeInputs',
  components: {
    CoMandateInputs
  },
  props: {
    property: {
      type: Object
    },
    prefillCoMandate: {
      type: Boolean,
      default: false
    }
  },
  constants: {
    ID_COMMISSION_TYPE_FREE: '18',
    ID_COMMISSION_TYPE_ONE_MONTH_RENT: '6',
    ID_COMMISSION_TYPE_5_PERCENT_ANNUAL_RENT: '17',
    ID_COMMISSION_TYPE_10_PERCENT_ANNUAL_RENT: '15',
    ID_COMMISSION_TYPE_15_PERCENT_ANNUAL_RENT: '9',
    ID_COMMISSION_TYPE_FIXED_VALUE_RENT: '12',
    TRANSACTION_TYPE_SALES: 2,
    TRANSACTION_TYPE_RENTAL: 3,
    TRANSACTION_TYPE_ACQUISITION: 4,
    RENTAL_COMMISSION_PERCENTAGE_MAP: {
      18: 0,
      6: 0,
      17: 5,
      15: 10,
      9: 15,
      12: 0
    }
  },
  data () {
    return {
      formValues: {
        commission_percentage_in: this.property.transaction_type !== 3 ? 3.3 : 0,
        commission_type_in_sales: 'percentage',
        commission_type_in_rental: '6', // 'ID_COMMISSION_TYPE_ONE_MONTH_RENT': const cannot be used here
        commission_fix_in: 0,
        mandate_start_date: this.property.mandate_start_date || today.date,
        mandate_type: this.prefillCoMandate ? 'co_mandate' : 'exclusive',
        send_monthly_owner_reports: true
      },
      price: this.property.price
    }
  },
  computed: {
    propertyId () {
      return this.property.id
    },
    isSales () {
      return [this.TRANSACTION_TYPE_SALES, this.TRANSACTION_TYPE_ACQUISITION].includes(this.property.transaction_type)
    },
    disableCommissionField () {
      if (this.formValues.has_transaction) return true // Disabled
      if (this.isSales && this.formValues.commission_type_in_sales === 'percentage') return true // Disabled
      if (this.isSales) return false
      return this.formValues.commission_type_in_rental !== this.ID_COMMISSION_TYPE_FIXED_VALUE_RENT
    },
    calculatedSalesCommission () {
      if (this.formValues.commission_type_in_sales === 'percentage') {
        return (this.price * (this.formValues.commission_percentage_in / 100)).toFixed(2)
      }
      return parseFloat(this.formValues?.commission_fix_in).toFixed(2)
    },
    calculatedRentalCommission () {
      if (this.formValues.commission_type_in_rental === this.ID_COMMISSION_TYPE_FREE) return 0
      if (this.formValues.commission_type_in_rental === this.ID_COMMISSION_TYPE_ONE_MONTH_RENT || this.formValues.commission_type_in_rental === this.ID_COMMISSION_TYPE_FIXED_VALUE_RENT) {
        if (this.formValues.commission_type_in_rental === this.ID_COMMISSION_TYPE_ONE_MONTH_RENT) return this.price
        return parseFloat(this.formValues.commission_rental_fix_value).toFixed(2)
      } else {
        const commissionPercentageIn = this.RENTAL_COMMISSION_PERCENTAGE_MAP[this.formValues.commission_type_in_rental]
        return (this.price * 12 * (commissionPercentageIn / 100)).toFixed(2)
      }
    },
    calculatedCommission () {
      if (this.isSales) {
        return this.calculatedSalesCommission
      }
      return this.calculatedRentalCommission
    },
    formattedCommission () {
      if (!this.calculatedCommission) return 0
      return this.currency(this.calculatedCommission)
    }
  },
  methods: {
    currency,
    handleCommissionTypeChange () {
      this.$set(this.formValues, 'commission_percentage_in', 0)
      this.$set(this.formValues, 'commission_fix_in', 0)
    },
    handleMandateTypeChange () {
      this.$set(this.formValues, 'co_mandate_collaborator_percentage_in', null)
      this.$set(this.formValues, 'co_mandate_other_percentage_in', null)
      if (this.formValues.mandate_type !== 'open_mandate') {
        this.$set(this.formValues, 'send_monthly_owner_reports', true)
      }
    },
    getRentalCommissionPercentageIn (commissionTypeInRental) {
      return this.RENTAL_COMMISSION_PERCENTAGE_MAP[commissionTypeInRental]
    },
    async createTransaction ({
      has_transaction,
      commission_percentage_in,
      commission_type_in_sales,
      commission_type_in_rental,
      commission_fix_in,
      mandate_type,
      mandate_signature_date
    }) {
      if (has_transaction) return false // We will not create a transaction if it already exists
      let commission_in = this.calculatedCommission
      let commission_external_amount_in = 0
      let co_mandate_percentage_type_in = null
      let co_mandate_collaborator_percentage_in = null
      let co_mandate_other_percentage_in = null
      if (mandate_type === 'co_mandate') {
        const coMandateValues = this.$refs.coMandateInputs.getValues()
        const coMandateCommissions = this.$refs.coMandateInputs.getCommissions()
        co_mandate_percentage_type_in = coMandateValues.co_mandate_percentage_type
        co_mandate_collaborator_percentage_in = coMandateValues.co_mandate_collaborator_percentage
        co_mandate_other_percentage_in = coMandateValues.co_mandate_other_percentage
        commission_in = coMandateCommissions.co_mandate_collaborator_commission
        commission_external_amount_in = coMandateCommissions.co_mandate_other_commission
      } else {
        // Just to make sure these values aren't accidentally set if it's not a co-mandate transaction
        co_mandate_percentage_type_in = null
        co_mandate_collaborator_percentage_in = null
        co_mandate_other_percentage_in = null
      }
      const payload = {
        property: this.propertyId,
        commission_fix_in: commission_in,
        commission_external_amount_in: commission_external_amount_in,
        mandate_type,
        co_mandate_percentage_type_in,
        co_mandate_collaborator_percentage_in,
        co_mandate_other_percentage_in,
        transaction_start_date: mandate_signature_date,
        should_create_transaction_offices: false
      }
      if (this.isSales) {
        if (commission_type_in_sales === 'fix') {
          payload.commission_fix_value_in = commission_fix_in
          payload.commission_percentage_in = 0
        } else {
          payload.commission_fix_value_in = 0
          payload.commission_percentage_in = commission_percentage_in
        }
      } else {
        payload.commission_percentage_in = this.getRentalCommissionPercentageIn(commission_type_in_rental)
        if (!this.isSales && (commission_type_in_rental === this.ID_COMMISSION_TYPE_FIXED_VALUE_RENT || commission_type_in_rental === this.ID_COMMISSION_TYPE_ONE_MONTH_RENT)) {
          payload.commission_fix_value_in = commission_in
        } else {
          payload.commission_fix_value_in = 0
        }
        payload.commission_type_in = commission_type_in_rental
      }
      const response = await createTransaction(payload)
      return response
    },
    async updateProperty ({
      mandate_start_date,
      mandate_signature_date,
      send_monthly_owner_reports
    }) {
      const payload = {
        mandate_start_date,
        mandate_signature_date,
        send_monthly_owner_reports
      }
      const response = await updateProperty(this.propertyId, payload)
      return response.data
    },
    async triggerSubmission () {
      const propertyResponse = await this.updateProperty(this.formValues)
      const transactionResponse = await this.createTransaction(this.formValues)
      return [propertyResponse, transactionResponse]
    },
    async createFolder () {
      const response = await createPropertyFileFolder(this.propertyId, { name: 'Verkoop- verhuuropdracht' })
      return response.data?.id
    },
    parseFileObj (file) {
      if (!file) return false
      // Formulate input requires filename and url
      const { id, filename, url } = file
      return [{ id, name: filename, url }]
    },
    async uploadFile (file, progress) {
      try {
        progress(0)
        const formData = new FormData()
        formData.append('file', file)
        progress(25)
        const { key, filename } = (await uploadFile(this.propertyId, formData)).data
        progress(50)
        const folderResponse = await getPropertyFileFolders(this.propertyId, { query: 'Verkoop- verhuuropdracht' })
        const folderId = folderResponse?.data?.results?.[0]?.id
        const folder_id = folderId || await this.createFolder()
        const payload = { key, filename, file_type: FILE_TYPES[this.property.transaction_type], folder_id }
        const response = await createPropertyFile(this.propertyId, payload)
        progress(100)
        return this.parseFileObj(response.data)
      } catch (error) {
        console.error(error)
        errorModal('Er ging iets mis bij het uploaden het bestand')
      }
    }
  }
}
</script>
