<template>
  <fieldset class="tw-px-4 tw-border-gray-cc tw-rounded">
    <legend class="tw--mx-1 tw-my-0 tw-px-1 tw-w-auto tw-font-bold tw-text-sm tw-text-tg-color">Commissie co-mandaat</legend>
    <FormulateInput
      v-model="formValues.co_mandate_percentage_type"
      type="radio"
      name="co_mandate_percentage_type"
      :options="coMandatePercentageTypeOptions"
      validation="required"
      :validation-messages="{
        required: 'Type percentage co-mandaatcommissie is verplicht'
      }"
      :input-class="['tw-my-1']"
      outer-class="tw-m-0"
      @input="handleMandatePercentageTypeChange"
    />
    <div v-show="formValues.co_mandate_percentage_type" class="tw-grid tw-grid-cols-2 tw-gap-x-4">
      <FormulateInput
        id="co_mandate_collaborator_percentage"
        v-model="formValues.co_mandate_collaborator_percentage"
        type="number"
        name="co_mandate_collaborator_percentage"
        label="Verkopende makelaar"
        placeholder="Percentage"
        validation="bail|required|number|min:0|validMandatePercentage"
        :validation-rules="{ validMandatePercentage }"
        :validation-messages="{
          validMandatePercentage: `De som van percentages moet ${requiredCoMandatePercentageSum}% zijn`
        }"
        step=".01"
        min="0"
        lang="nl"
        outer-class="tw-my-4"
        :element-class="['tw-flex tw-items-center']"
        :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
      >
        <div slot="suffix" class="input-unit">
          <i class="fas fa-percent" />
        </div>
      </FormulateInput>
      <FormulateInput
        id="co_mandate_other_percentage"
        v-model="formValues.co_mandate_other_percentage"
        type="number"
        name="co_mandate_other_percentage"
        label="Niet-verkopende makelaar"
        placeholder="Percentage"
        validation="bail|required|number|min:0|validMandatePercentage"
        :validation-rules="{ validMandatePercentage }"
        :validation-messages="{
          validMandatePercentage: `De som van percentages moet ${requiredCoMandatePercentageSum}% zijn`
        }"
        step=".01"
        min="0"
        lang="nl"
        outer-class="tw-my-4"
        :element-class="['tw-flex tw-items-center']"
        :input-class="['tw-rounded-tr-none tw-rounded-br-none']"
      >
        <div slot="suffix" class="input-unit">
          <i class="fas fa-percent" />
        </div>
      </FormulateInput>
    </div>
    <p v-if="coMandateCommissions">
      Commissie verkopende makelaar: {{ currency(coMandateCommissions.co_mandate_collaborator_commission)}}
      <br>
      Commissie niet-verkopende makelaar: {{ currency(coMandateCommissions.co_mandate_other_commission)}}
    </p>
  </fieldset>
</template>

<script>
import { currency } from '@/utils/helpers'
export default {
  name: 'CoMandateInputs',
  props: {
    price: {
      type: Number,
      required: false
    },
    transactionType: {
      type: Number // 2: for sale, 3: for rent, 4: acquisition
    },
    commissionType: {
      type: String // 'percentage' or 'fix'
    },
    commissionPercentage: { // Not applicable in case of fix
      type: Number,
      required: false
    },
    calculatedCommission: { // To calculate the final commissions for broker & co-broker
      type: Number
    },
    initialValues: { // co_mandate_percentage_type, co_mandate_collaborator_percentage, co_mandate_other_percentage
      type: Object,
      required: false
    },
    clearPercentageInputs: {
      type: Boolean,
      required: false
    }
  },
  constants: {
    TRANSACTION_TYPE_SALES: 2,
    TRANSACTION_TYPE_RENTAL: 3
  },
  created () {
    this.formValues = this.initialValues || {}
  },
  data () {
    return {
      formValues: {}
    }
  },
  watch: {
    clearPercentageInputs () {
      this.$set(this.formValues, 'co_mandate_collaborator_percentage', null)
      this.$set(this.formValues, 'co_mandate_other_percentage', null)
    }
  },
  computed: {
    isSales () {
      return [this.TRANSACTION_TYPE_SALES, this.TRANSACTION_TYPE_ACQUISITION].includes(this.transactionType)
    },
    coMandatePercentageTypeOptions () {
      const options = [{ value: 0, label: 'Procentuele verdeling afgesproken ereloon bij opdracht' }]
      if (this.commissionType !== 'fix' && this.isSales) {
        options.push({ value: 1, label: 'Procentuele verdeling commissiepercentage' })
      }
      return options
    },
    requiredCoMandatePercentageSum () {
      if (this.formValues.co_mandate_percentage_type === '0' || this.commissionType === 'fix') return 100
      return parseFloat(this.commissionPercentage)
    },
    coMandateCommissionsCommissionPercentageSplit () {
      return {
        co_mandate_collaborator_commission: (this.price * (this.formValues.co_mandate_collaborator_percentage / 100)).toFixed(2),
        co_mandate_other_commission: (this.price * (this.formValues.co_mandate_other_percentage / 100)).toFixed(2)
      }
    },
    coMandateCommissionsFixSplit () {
      return {
        co_mandate_collaborator_commission: (this.calculatedCommission * (this.formValues.co_mandate_collaborator_percentage / 100)).toFixed(2),
        co_mandate_other_commission: (this.calculatedCommission * (this.formValues.co_mandate_other_percentage / 100)).toFixed(2)
      }
    },
    coMandateCommissions () {
      if (!this.formValues.co_mandate_collaborator_percentage || !this.formValues.co_mandate_other_percentage || !this.price) return null
      if (this.formValues.co_mandate_percentage_type === '0' || this.commissionType === 'fix') return this.coMandateCommissionsFixSplit
      if (this.formValues.co_mandate_percentage_type === '1') return this.coMandateCommissionsCommissionPercentageSplit
      return null
    }
  },
  methods: {
    currency,
    handleMandatePercentageTypeChange () {
      this.$set(this.formValues, 'co_mandate_other_percentage', null)
      this.$set(this.formValues, 'co_mandate_collaborator_percentage', null)
    },
    validMandatePercentage ({ getFormValues }) {
      const { co_mandate_collaborator_percentage, co_mandate_other_percentage } = getFormValues()
      if (!co_mandate_collaborator_percentage || !co_mandate_other_percentage) return true
      const sum = parseFloat(co_mandate_collaborator_percentage) + parseFloat(co_mandate_other_percentage)
      // Need to round the sum to 2 digits to compare correctly
      return parseFloat(sum.toFixed(2)) === this.requiredCoMandatePercentageSum
    },
    getCommissions () {
      return this.coMandateCommissions
    },
    getValues () {
      return {
        co_mandate_percentage_type: this.formValues.co_mandate_percentage_type,
        co_mandate_collaborator_percentage: this.formValues.co_mandate_collaborator_percentage,
        co_mandate_other_percentage: this.formValues.co_mandate_other_percentage
      }
    }
  }
}
</script>
