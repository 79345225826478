<template>
  <FormulateForm
    #default="{ isLoading }"
    v-model="values"
    name="wizardDossierDetailsStep4Form"
    invalid-message="Gelieve de verplichte velden correct in te vullen."
    @submit="submit"
  >
    <h3>Bedrijfsvastgoed</h3>
    <CustomRadioGroup
      v-model="values.rolling_bridge"
      :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="rolling_bridge"
      label="Rolbrug"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.electricity_cabin"
      :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="electricity_cabin"
      label="Elektriciteitscabine"
      class="tw-mb-4"
    />
    <CustomRadioGroup
      v-model="values.three_phase_electric_power"
      :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="three_phase_electric_power"
      label="3-fasenaansluiting"
      class="tw-mb-4"
    />
    <FormulateInput
        type="number"
        name="kva"
        label="kVa"
        min="0"
        step=".01"
        :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
        :input-class="['tw-rounded-tr-none tw-rounded-br-none !tw-mt-1']"
        :label-class="['tw-block md:tw-flex tw-items-center']"
        :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
    >
      <div slot="suffix" class="input-unit">
        kVA
      </div>
    </FormulateInput>
    <CustomRadioGroup
      v-model="values.loading_docks"
      :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="loading_docks"
      label="Laadkades"
      class="tw-mb-4"
    />
    <FormulateInput
      v-if="values.loading_docks === 1"
      type="number"
      name="number_of_loading_docks"
      label="Aantal laadkades"
      min="0"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :input-class="['tw-rounded-tr-none tw-rounded-br-none !tw-mt-1']"
      :label-class="['tw-block md:tw-flex tw-items-center md:tw-pl-8']"
      :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
    />
    <FormulateInput
      type="number"
      name="number_of_ports"
      label="Aantal poorten"
      min="0"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :input-class="['tw-rounded-tr-none tw-rounded-br-none !tw-mt-1']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
    />
    <FormulateInput
      type="number"
      name="free_height"
      label="Vrije hoogte"
      min="0"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :input-class="['tw-rounded-tr-none tw-rounded-br-none !tw-mt-1']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
    >
      <div slot="suffix" class="input-unit">
        m
      </div>
    </FormulateInput>
    <FormulateInput
      type="number"
      name="ride_height"
      label="Nokhoogte"
      min="0"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :input-class="['tw-rounded-tr-none tw-rounded-br-none !tw-mt-1']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
    >
      <div slot="suffix" class="input-unit">
        m
      </div>
    </FormulateInput>
    <FormulateInput
      type="number"
      name="usable_office_space"
      label="Kantooroppervlakte"
      min="0"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :input-class="['tw-rounded-tr-none tw-rounded-br-none !tw-mt-1']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
    >
      <div slot="suffix" class="input-unit">
        m²
      </div>
    </FormulateInput>
    <FormulateInput
      type="number"
      name="usable_storage_space"
      label="Opslagoppervlakte"
      min="0"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :input-class="['tw-rounded-tr-none tw-rounded-br-none !tw-mt-1']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
    >
      <div slot="suffix" class="input-unit">
        m²
      </div>
    </FormulateInput>
    <FormulateInput
      type="translatable"
      name="commercial_description"
      label="Extra info"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3 tw-w-full']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :outer-class="['tw-flex tw-items-center']"
      :element-class="['tw-col-span-2']"
    />
    <div class="tw-grid tw-grid-cols-2 md:tw-flex tw-justify-end tw-gap-x-2">
      <FormulateErrors />
      <FormulateInput
        type="button"
        :input-class="['tw-w-full tw-bg-gray-500']"
        :disabled="saving"
        @click="goBack"
      >
        <i class="fas tw-mr-2 fa-arrow-left" />
        Vorige <span class="tw-hidden md:tw-inline">stap</span>
      </FormulateInput>
      <FormulateInput
        type="submit"
        title="Volgende stap"
        :input-class="['tw-w-full']"
        :disabled="isLoading"
      >
        <i
          :class="[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-arrow-right'
          ]"
        />
        Volgende <span class="tw-hidden md:tw-inline">stap</span>
      </FormulateInput>
    </div>
  </FormulateForm>
</template>

<script>
import { YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS } from '@/forms/selectOptions'
import CustomRadioGroup from '@/components/base/CustomRadioGroup'
import { updatePropertyCommercial } from '@/services/properties'

export default {
  name: 'WizardDossierDetailsStep4',
  components: {
    CustomRadioGroup
  },
  constants: {
    YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS
  },
  props: {
    initialValues: {
      type: Object
    },
    propertyId: {
      type: [Number, String]
    }
  },
  data () {
    return {
      values: {},
      saving: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.values = { ...this.initialValues }
    },
    async goBack () {
      this.saving = true
      const response = await this.submit(this.values, false)
      this.saving = false
      return response
    },
    async submit (data, goForward = true) {
      try {
        const commercialResponse = await updatePropertyCommercial(this.propertyId, data)
        if (goForward) this.$emit('finished')
        else this.$emit('goBack')
        return commercialResponse
      } catch (error) {
        this.$formulate.handle(error, 'wizardDossierDetailsStep4Form')
        throw error
      }
    }
  }
}
</script>
